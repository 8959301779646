.about-container {
    padding: 40px;
    background-color: #1c1c1c;
    border-radius: 10px;
    margin: 20px auto;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
    max-width: 900px;
    width: 100%;
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-container:hover {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.3);
}

.profile-section {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
}

.profile-image-container {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.profile-image-container:hover .profile-image {
    transform: scale(1.05);
}

.profile-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.profile-image-container:hover .profile-hover {
    opacity: 1;
}

.about-content {
    flex: 1;
    max-width: 600px;
}

.about-heading {
    font-size: 36px;
    color: #f39c12;
    margin-bottom: 25px;
    font-weight: bold;
}

.about-paragraph {
    font-size: 18px;
    color: #d0d0d0;
    margin: 20px 0;
    line-height: 1.7;
}

.skills-container {
    margin-top: 20px;
}

.skills-list {
    list-style-type: none;
    padding-left: 0;
    color: #f39c12;
}

.skills-list li {
    margin-bottom: 10px;
}
.skills-list li:last-child {
    margin-bottom: 30px; /* Add spacing below the last item (Cybersecurity) */
}

.resume-link {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    padding: 12px 30px;
    background-color: #f39c12;
    border-radius: 5px;
    transition: background-color 0.3s, box-shadow 0.3s ease;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    margin-top: 30px;
}

.resume-link:hover {
    background-color: #e67e22;
    box-shadow: 0 0 25px rgba(243, 156, 18, 0.6), 0 0 35px rgba(243, 156, 18, 0.4);
}

.social-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
}

.social-button {
    display: inline-block;
    padding: 10px 25px;
    background-color: #2c2c2c;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.social-button:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.8);
}

.icon {
    margin-right: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .about-container {
        padding: 30px;
        margin: 10px;
    }

    .about-heading {
        font-size: 32px;
    }

    .about-paragraph {
        font-size: 16px;
    }

    .social-button {
        padding: 8px 20px;
        font-size: 14px;
    }

    .resume-link {
        font-size: 16px;
        padding: 10px 25px;
    }

    .profile-section {
        flex-direction: column;
        align-items: center;
    }
}
