/* Badges.css */

.container {
    text-align: center;
    padding: 50px;
    background-color: #2c2c2c; /* Dark grey background */
    border-radius: 10px;
    margin: 20px; /* Add margin for spacing */
    margin-bottom: 200px;
}

.heading {
    font-size: 36px;
    color: #f39c12; /* Subtle accent color for headings */
    margin-bottom: 30px;
}

.badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px; /* Increases space between badges for better visibility */
}

.badge {
    transition: transform 0.3s, box-shadow 0.3s, border 0.3s;
    text-decoration: none;
    border: 2px solid transparent; /* Default border */
    border-radius: 8px; /* Rounded corners */
    background-color: rgba(255, 255, 255, 0.1); /* Subtle transparent background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow */
    width: 120px; /* Fixed width for symmetry */
    height: 120px; /* Fixed height for symmetry */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centers content vertically */
}

.badge:hover {
    transform: scale(1.05); /* Slightly enlarge badge on hover */
    box-shadow: 0 0 20px rgba(243, 156, 18, 0.6), 0 0 40px rgba(243, 156, 18, 0.6); /* Glow effect */
    border: 2px solid #f39c12; /* Subtle accent color for hover */
}

.image {
    width: 80px; /* Consistent image size */
    height: 80px; /* Consistent image size */
    border-radius: 50%; /* Circular badges */
    background-color: #1c1c1c; /* Dark background for badge image */
    padding: 10px; /* Add some padding */
}

.title {
    margin-top: 5px;
    color: #e0e0e0; /* Light grey text for titles */
    font-size: 14px;
}

/* Styles for Credential Info */
.credential-info {
    margin-top: 30px; /* Spacing from badges */
    color: #e0e0e0; /* Light grey text */
}


.credential-link {
    background-color: #2c2c2c; /* Dark background */
    color: #e0e0e0; /* Light grey text */
    border: none; /* No border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Padding for the button */
    text-decoration: none; /* Remove underline */
    font-size: 16px; /* Font size */
    transition: transform 0.3s, box-shadow 0.3s; /* Animation for hover */
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5); /* Glow effect */
    margin-top: 50px; 
    display: inline-block;
}

.credential-link:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0 0 20px rgba(243, 156, 18, 0.8); /* Stronger glow effect on hover */
}


.click-instruction {
    color: #e0e0e0; /* Light grey text */
    font-size: 14px; /* Font size for visibility */
    margin: 30px; /* Space below the instruction */
    
}

