/* Main container */
.contact-container {
    text-align: center;
    padding: 50px;
    background-color: #2c2c2c;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    margin-bottom: 100px;
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

/* Contact heading */
.contact-heading {
    font-size: 36px;
    color: #f39c12;
    margin-bottom: 30px;
}

/* Contact form */
.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Input group styles */
.input-group {
    margin-bottom: 20px;
    width: 100%;
    max-width: 500px; /* Increased max-width for better layout */
    text-align: left;
    padding: 0 15px; /* Added padding for inner spacing */
}

.label {
    display: block;
    margin-bottom: 5px;
    color: #e0e0e0;
}

/* Input and textarea fields */
.input, .textarea {
    padding: 12px;
    border-radius: 5px;
    border: 2px solid #4a4a4a;
    width: 100%;
    color: #e0e0e0;
    background-color: #1a1a1a;
}

/* Textarea style */
.textarea {
    min-height: 120px;
}

/* Button */
.button {
    padding: 12px 25px;
    background-color: #f39c12;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #d35400;
}

/* Media Queries */

/* Tablets and smaller screens */
@media (max-width: 768px) {
    .contact-container {
        padding: 30px;
        margin-bottom: 50px;
    }
    .contact-heading {
        font-size: 28px;
    }
    .button {
        padding: 10px 20px;
    }
    .input, .textarea {
        padding: 10px;
    }
}

/* Mobile screens */
@media (max-width: 480px) {
    .contact-container {
        padding: 20px;
        margin-bottom: 40px;
    }
    .contact-heading {
        font-size: 24px;
    }
    .input, .textarea {
        padding: 8px;
    }
    .button {
        padding: 8px 16px;
    }
    /* Adjust max-width and spacing for mobile */
    .input-group {
        max-width: 100%;
        padding: 0 10px; /* Reduced padding */
    }
}
