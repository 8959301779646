/* src/components/Navbar.css */
.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #1c1c1c;
    padding: 15px;
    display: flex;
    justify-content: space-between; /* Space between elements */
    align-items: center;
    border-bottom: 2px solid #333;
    z-index: 999;
    font-family: 'Arial', sans-serif; /* New font */
}

.navbar-container {
    display: flex;
    align-items: center;
    width: 100%; /* Ensures the container spans the full width */
    justify-content: space-between; /* Space out the elements to the sides */
}

.navbar-toggle {
    display: none; /* Hidden by default on desktop */
    flex-direction: column;
    cursor: pointer;
    margin-left: auto; /* This will push the hamburger menu to the right */
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #e0e0e0;
    margin: 3px 0;
    transition: 0.3s;
}

.navbar-links {
    display: flex;
    gap: 30px;
    align-items: center;
}

.navbar-link {
    color: #e0e0e0;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
    font-family: 'Arial', sans-serif; /* Ensures the link text has the new font */
}

.navbar-link:hover {
    color: #f39c12;
    transform: scale(1.05);
}

/* Media Query for mobile screens */
@media (max-width: 768px) {
    /* This ensures navbar links are hidden initially on mobile */
    .navbar-links {
        display: none; /* Hidden by default */
        flex-direction: column;
        width: 100%; /* Full width */
        position: absolute; /* Position absolute for dropdown */
        top: 60px; /* Adjust to your navbar height */
        left: 0;
        background-color: #1c1c1c;
    }

    /* Show navbar links when the toggle button is clicked */
    .navbar-links.active {
        display: flex; /* Show links when toggled */
    }

    /* Show the hamburger menu toggle on mobile */
    .navbar-toggle {
        display: flex; /* Show hamburger icon on mobile */
        margin-right: 35px; /* Add some space from the right edge */
    }
}
